import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import './NaukList.sass'
import { find, isEmpty, orderBy } from 'lodash'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onFeedDelete from '../NaukManager/functions/deleteFeed'
import SortableList from '../../../ui/SortableList/SortableList'
import arrayMove from 'array-move'
import { updateDoc } from '../../../utils/db/updateDoc'
import parse from 'html-react-parser'

function NaukList({ ...router }) {
  const [feedCatalog, setFeedCatalog] = useState(null)
  const [deleteFeed, setDeleteFeed] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    db.collection('nauk').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ naukId: d.id, ...d.data() }))
      setFeedCatalog(data)
    })
  }, [])

  return (
    <RouteComponent
      title="Наукова діяльність"
      controlls={
        <>
          <Button
            title="Додати запис"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/pages/nauk/add"
            size={28}
          />
        </>
      }
    >
      {deleteFeed && (
        <DeletePopUp
          title="Видалення запису"
          warningMessage={`Ви впевнені, що хочете видалити запис від "${formatDateAsDayMontYearHHMM(
            deleteFeed.created
          )}"?`}
          closePopUp={() => setDeleteFeed(null)}
          submitDelete={() => onFeedDelete({ naukId: deleteFeed.naukId })}
        />
      )}
      <div className="ToursCatalog">
        {feedCatalog ? (
          isEmpty(feedCatalog) ? (
            <p className="EmptyText">Немає записів</p>
          ) : (
            feedCatalog.map((t, i) => (
              <TourTile
                key={`nauk-${i}`}
                {...t}
                onEdit={() =>
                  router.history.push(`/pages/nauk/edit/${t.naukId}`)
                }
                onDelete={() =>
                  setDeleteFeed({
                    created: t.created,
                    naukId: t.naukId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function TourTile({ video, text, onEdit, onDelete, created }) {
  return (
    <div className="FeedTile" onDoubleClick={onEdit}>
      <div className="Content">
        <InfoLine title="Дата" content={formatDateAsDayMontYearHHMM(created)} />
        <InfoLine title="Текст" content={parse(text)} />
      </div>
      <div className="Options">
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(NaukList)
