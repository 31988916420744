import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import NaukList from './NaukList/NaukList'
import NaukManager from './NaukManager/NaukManager'

function Routes() {
  return (
    <>
      <Route path="/pages/nauk" exact component={NaukList} />
      <Route path="/pages/nauk/add" exact component={NaukManager} />
      <Route path="/pages/nauk/edit/:id" exact component={NaukManager} />
    </>
  )
}

export default Routes
