import { omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../../../ui/Button/Button'
import StatusMessage, {
  initStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import { updateDoc } from '../../../utils/db/updateDoc'
import syncForm from '../../../utils/newforms/changed/syncForm'
import { createForm } from '../../../utils/newforms/createForm'
import getFormValues from '../../../utils/newforms/getFormValues'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import uploadPhotos from '../../Doctors/DoctorsManager/functions/uploadPhotos'
import PhotoManager from '../../PhotoManager/PhotoManager'
import RouteComponent from '../../RouteComponent/RouteComponent'
import './NewsManager.sass'

function NewsManager({ accountId, uid, ...router }) {
  const newsId = router.match.params.id

  const [form, setForm] = useState(null)
  const [sections, setSections] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useState(initStatusMessage())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDoc({ path: 'news', docId: newsId }).then((result) => {
      let formData = {}
      if (result) formData = result

      setForm(
        createForm({
          formData,
          formPattern: new NewsForm(),
        })
      )
    })
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message:
          'Неможливо зберегти новину. Будь ласка, заповніть обовязкові поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)
    const values = getFormValues({ form })
    const data = {
      ...values,
      updated: new Date(),
      photos: values.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
    }
    const opRef = newsId
      ? updateDoc({ path: 'news', docId: newsId, data })
      : addDoc({ path: 'news', data })

    opRef.then((docId) =>
      uploadPhotos({
        photos: values.photos,
        docId,
        docPath: `news`,
        storagePath: 'news',
      })
        .then(() => {
          router.history.push(`/news/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Дані збережено',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  return (
    <RouteComponent title={newsId ? 'Редагувати новину' : 'Створити новину'}>
      <div className="NewsManager">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <FormRender
          sections={[{ fields: ['status', 'title', 'shortNews', 'longNews'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <PhotoManager
          projectId={newsId}
          projectPath="news"
          formData={form ? form.photos.values : null}
          syncState={(moduleState) =>
            syncForm({
              propIdentifier: 'photos',
              form,
              setForm,
              newForm: moduleState,
            })
          }
        />
        <div className="Buttons">
          <Button
            title="Зберегти"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрити"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            onClick={() => router.history.push('/news')}
          />
        </div>
      </div>
    </RouteComponent>
  )
}
class NewsForm {
  constructor() {
    this.status = {
      field: {
        fieldId: 'status',
        fieldType: 'select',
        label: 'Статус',
        value: 'draft',
        required: true,
        getOptions: [
          { label: 'Опубліковано', value: 'published' },
          { label: 'Чорновик', value: 'draft' },
        ],
        systemField: true,
      },
      render: getFieldRenderObject(),
    }
    this.shortNews = {
      field: {
        fieldId: 'shortNews',
        fieldType: 'texteditor',
        label: 'Короткий текст новини',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.longNews = {
      field: {
        fieldId: 'longNews',
        fieldType: 'texteditor',
        label: 'Повний текст новини',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Заголовок',
        // value: '1',
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: {
        fieldId: 'photos',
        value: [],
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: {
        fieldId: 'isDeleted',
        value: false,
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: new Date(),
        systemField: true,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(NewsManager)
