import React, { useEffect, useState } from 'react'
import { find, isArray, isEmpty } from 'lodash'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import DeleteNewsPopUp from './DeleteNewsPopUp'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import Icon from '../../../ui/Icon/Icon'
import { Img } from 'react-image'

function Newslist({ accountId, accountUser, ...router }) {
  const [isLoading, setIsLoading] = useState(false)
  const [news, setNews] = useState(null)
  const [showPopUp, setShowPopUp] = useState(null)

  useEffect(() => {
    db.collection(`/news`).onSnapshot((snap) => {
      const result = []
      snap.docs.forEach((n) => result.push({ newsId: n.id, ...n.data() }))
      const actualNews = result.filter((n) => n.isDeleted === false)
      setNews(actualNews)
    })
  }, [])

  return (
    <RouteComponent
      title="Новини"
      controlls={
        <>
          <Button
            title="Додати новину"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/news/add"
            size={28}
          />
        </>
      }
    >
      <div className="DoctorsCatalog">
        {showPopUp && (
          <DeleteNewsPopUp
            newsId={showPopUp && showPopUp.newsId}
            closePopUp={() => setShowPopUp(null)}
          />
        )}
        {news ? (
          isEmpty(news) ? (
            <p className="EmptyText">Немає новин</p>
          ) : (
            news.map((n, i) => (
              <NewsTile
                key={`news-${i}`}
                {...n}
                onEdit={() => router.history.push(`/news/${n.newsId}`)}
                onDelete={() =>
                  setShowPopUp({
                    title: n.title,
                    newsId: n.newsId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function NewsTile({
  title,
  specialization,
  updated,
  photos,
  onEdit,
  onDelete,
}) {
  return (
    <div className="DoctorTile" onDoubleClick={onEdit}>
      <div className="Avatar">
        {!isEmpty(photos) ? (
          <Img src={find(photos, ['isAvatar', true]).publicUrl} />
        ) : (
          <div className="DoctorAvatar">
            <Icon name="newspaper" />
          </div>
        )}
      </div>
      <div className="Content">
        <p className="Title">{title}</p>
      </div>
      <div className="Options">
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(Newslist)
