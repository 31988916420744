import React, { useEffect, useState } from 'react'
import './Slider.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import Button from '../../ui/Button/Button'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../RouteComponent/RouteComponent'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import { isNull, omit } from 'lodash'
import Spinner from '../../ui/Spinner/Spinner'
import { createForm } from '../../utils/newforms/createForm'
import FieldRender from '../../utils/newforms/render/FieldRender'
import fileToBase64 from '../../utils/files/fileToBase64'
import uploadDocument from './functions/uploadDocument'
import FormRender from '../../utils/newforms/render/FormRender'
import PhotoManager from '../PhotoManager/PhotoManager'
import syncForm from '../../utils/newforms/changed/syncForm'
import getDoc from '../../utils/db/getDoc'
import getFormValues from '../../utils/newforms/getFormValues'
import { updateDoc } from '../../utils/db/updateDoc'
import uploadPhotos from '../Doctors/DoctorsManager/functions/uploadPhotos'

function Slider({ ...router }) {
  const [form, setForm] = useState(null)

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDoc({ path: 'slider', docId: 'homeSlider' }).then((data) => {
      setForm(
        createForm({
          formPattern: new SliderForm(),
          formData: data,
        })
      )
    })
  }, [])

  const onSubmit = () => {
    setIsLoading(true)

    const data = getFormValues({ form })

    const values = {
      ...data,
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
    }

    updateDoc({ path: 'slider', docId: 'homeSlider', data: values }).then(
      (docId) =>
        uploadPhotos({
          photos: data.photos,
          docId,
          docPath: 'slider',
          storagePath: 'slider',
        })
          .then(() => {
            setIsLoading(false)
            setStatusMessage({
              show: true,
              type: 'success',
              message: 'Дані збережено',
              closeAfter: 5000,
            })
            window.scrollTo({ top: 0, behavior: 'smooth' })
          })
          .catch((error) => {
            console.log(
              '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
              error
            )
          })
    )
  }

  return (
    <RouteComponent title={'Cлайдер'}>
      {!isNull(form) ? (
        <div className="Settings-Content">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <div className="MainInfo">
            <FormRender
              sections={[
                {
                  fields: ['actions'],
                },
              ]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </div>
          <p className="Title">Фото</p>
          <PhotoManager
            projectId={'homeSlider'}
            projectPath={'slider'}
            formData={form ? form.photos.values : null}
            syncState={(moduleState) =>
              syncForm({
                propIdentifier: 'photos',
                form,
                setForm,
                newForm: moduleState,
              })
            }
          />

          <div className="Buttons">
            <Button
              title="Зберегти"
              theme="solid"
              fill="accent"
              iconPosition="right"
              size={48}
              isLoading={isLoading}
              onClick={onSubmit}
            />
          </div>
        </div>
      ) : (
        <Spinner type="module" />
      )}
    </RouteComponent>
  )
}

class SliderForm {
  constructor() {
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.actions = {
      field: {
        fieldId: 'actions',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'text',
              fieldType: 'texteditor',
              label: 'Акційний текст',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'link',
              fieldType: 'input',
              inputType: 'text',
              label: 'Посилання (опційно)',
            },
            render: getFieldRenderObject(),
          },
        ],
        required: false,
        systemField: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default withRouter(Slider)
