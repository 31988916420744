import React, { useState, useEffect } from 'react'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import getDoc from '../../../utils/db/getDoc'
import { updateDoc } from '../../../utils/db/updateDoc'

const DeleteNewsPopUp = ({ newsId, closePopUp, uid }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')

  const onDelete = async () => {
    setIsLoading(true)
    updateDoc({
      path: 'news',
      docId: newsId,
      data: { isDeleted: true },
      uid,
    }).then(() => closePopUp())
  }

  useEffect(() => {
    getDoc({ path: 'news', docId: newsId }).then((news) =>
      setTitle(`${news.title}`)
    )
  }, [])

  return (
    <DeletePopUp
      title="Видалення новини"
      warningMessage={
        <>
          Ви впевнені, що хочете видалити новину{' '}
          <span className="Bolder">"{title}"</span>?
        </>
      }
      fieldLabel="Чтобы удалить эту новость, введите ее идентификатор:"
      identifier={newsId}
      closePopUp={closePopUp}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

export default DeleteNewsPopUp
