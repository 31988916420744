import React, { useEffect, useState } from 'react'
import './NaukManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../RouteComponent/RouteComponent'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { isEmpty, omit, pick } from 'lodash'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import uploadVideo from './functions/uploadVideo'
import FieldRender from '../../../utils/newforms/render/FieldRender'
import fileToBase64 from '../../../utils/files/fileToBase64'

function FeedsManager({ ...router }) {
  const feedId = router.match.params.id
  const [form, setForm] = useState(
    !feedId ? createForm({ formPattern: new FeedForm() }) : null
  )
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (feedId) {
      getDoc({ path: 'nauk', docId: feedId }).then((data) =>
        setForm(
          createForm({
            formPattern: new FeedForm(),
            formData: data,
          })
        )
      )
    }
  }, [feedId])

  const onFileAdded = (newForm, fieldId) => {
    fileToBase64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля форми',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const values = getFormValues({ form })

    const opRef = feedId
      ? updateDoc({ path: 'nauk', docId: feedId, data: values })
      : addDoc({ path: 'nauk', data: values })

    opRef
      .then((docId) => {
        router.history.push(`/pages/nauk/edit/${docId}`)
        setIsLoading(false)
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Дані збережено',
          closeAfter: 5000,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((error) => {
        console.log(
          '🚀 ~ file: FeedsManager.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <RouteComponent title={feedId ? 'Редагувати запис' : 'Додати новий запис'}>
      <div className="FeedsManager-Content">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <div className="MainInfo">
          <FormRender
            sections={[
              {
                fields: ['text', 'video'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          {/* {!isEmpty(form) && (
            <FieldRender
              field={form.video}
              form={form}
              setForm={(newForm) => onFileAdded(newForm, 'video')}
              showErrors={showErrors}
            />
          )} */}
        </div>

        <div className="Buttons">
          <Button
            title="Зберегти"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрити"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            isLoading={isLoading}
            onClick={() => router.history.push('/pages/nauk')}
          />
        </div>
      </div>
    </RouteComponent>
  )
}

class FeedForm {
  constructor() {
    this.text = {
      field: {
        fieldId: 'text',
        fieldType: 'texteditor',
        label: 'Текст',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.video = {
      field: {
        fieldId: 'video',
        fieldType: 'input',
        inputType: 'text',
        label: 'Посилання на відеофайл',
      },
      render: getFieldRenderObject(),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(FeedsManager)
