import React, { useState } from 'react'
import './Contacts.sass'
import { createForm } from '../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../utils/newforms/render/FormRender'
import { useEffect } from 'react'
import getDoc from '../../utils/db/getDoc'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import { updateDoc } from '../../utils/db/updateDoc'
import Button from '../../ui/Button/Button'

function Contacts() {
  const [form, setForm] = useState(
    createForm({
      formPattern: new ContactsForm(),
    })
  )

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDoc({ path: 'pages', docId: 'contacts' }).then((data) => {
      setForm(
        createForm({
          formPattern: new ContactsForm(),
          formData: data,
        })
      )
    })
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля форми',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })

    updateDoc({ path: 'pages', docId: 'contacts', data })
      .then((docId) => {
        setIsLoading(false)
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Дані збережено',
          closeAfter: 5000,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((error) => {
        console.log(
          '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <div className="Contacts">
      <h5>Контакти</h5>
      {statusMessage.show && (
        <StatusMessage
          className="Site-StatusMessage"
          type={statusMessage.type}
          message={statusMessage.message}
        />
      )}
      <FormRender
        sections={[
          {
            fields: ['map', 'phone', 'email', 'text'],
          },
        ]}
        form={form}
        setForm={setForm}
        errors={showErrors}
      />
      <div className="Buttons">
        <Button
          title="Зберегти"
          theme="solid"
          fill="accent"
          iconPosition="right"
          size={48}
          isLoading={isLoading}
          onClick={onSubmit}
        />
      </div>
    </div>
  )
}

class ContactsForm {
  constructor() {
    this.map = {
      field: {
        fieldId: 'map',
        fieldType: 'input',
        inputType: 'text',
        label: 'Координати тегу на карті',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'input',
        inputType: 'text',
        label: 'Контактний телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'text',
        label: 'Контактний E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.text = {
      field: {
        fieldId: 'text',
        fieldType: 'texteditor',
        inputType: 'text',
        label: 'Сторінка контактів',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default Contacts
