import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import NaukaList from './NaukaList/NaukaList'
import NaukaManager from './NaukaManager/NaukaManager'

function Routes({ id, title }) {
  return (
    <>
      <Route
        path={`/pages/${id}`}
        exact
        render={() => <NaukaList id={id} title={title} />}
      />
      <Route
        path={`/pages/${id}/add`}
        exact
        render={() => <NaukaManager id={id} />}
      />
      <Route
        path={`/pages/${id}/edit/:id`}
        exact
        render={() => <NaukaManager id={id} />}
      />
    </>
  )
}

export default Routes
