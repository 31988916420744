import React from 'react'
import { Route } from 'react-router-dom'
import SmiList from './SmiList/SmiList'
import SmiManager from './SmiManager/SmiManager'

function Routes() {
  return (
    <>
      <Route path="/pages/smi" exact component={SmiList} />
      <Route path="/pages/smi/add" exact component={SmiManager} />
      <Route path="/pages/smi/edit/:id" exact component={SmiManager} />
    </>
  )
}

export default Routes
