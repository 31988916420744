import React, { useContext, useEffect, useState } from 'react'
import RouteComponent from '../RouteComponent/RouteComponent'
import Button from '../../ui/Button/Button'
import { AuthContext } from '../../App'
import './Price.sass'
import CategoryManager from './CategoryManager/CategoryManager'
import addDoc from '../../utils/db/addDoc'
import getCollection from '../../utils/db/getCollection'
import promiseAllValues from '../../utils/promiseAllValues'
import CategoryPriceTile from './CategoryPriceTile/CategoryPriceTile'
import { isEmpty, toInteger } from 'lodash'
import { priceData } from './priceData'

// import Button from '../../../../../../ui/Button/Button'
// import CategoryManager from './CategoryManager/CategoryManager'
// import './CategoryPrice.sass'
// import { db } from '../../../../../../config/firebase'
// import rootDbPath from '../../../../../../utilities/db/rootDbPath'
// import { filter, isEmpty } from 'lodash'
import DeleteCategoryManager from './CategoryManager/DeleteCategoryManager'
import ServiceManager from './ServiceManager/ServiceManager'
// import CategoryPriceTile from './CategoryPriceTile/CategoryPriceTile'
// import ServiceManager from './ServiceManager/ServiceManager'
// import DeleteServiceManager from './ServiceManager/DeleteServiceManager'

function CategoryPrice({ accountId, extensionId, officeId }) {
  const [popUp, setPopUp] = useState({ type: null, instanceId: null })
  // const { cities } = useContext(AuthContext)
  // const [activeCityId, setActiveCityId] = useState(cities[0].id)

  const [categories, setCategories] = useState(null)
  const [services, setServices] = useState(null)

  const togglePopUp = ({
    type,
    show,
    editMode = false,
    instanceId = null,
    serviceId = null,
  }) => setPopUp({ type, show, editMode, instanceId, serviceId })

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
        orderBy: { fieldPath: 'pos', orderDir: 'asc' },
      }),
      services: getCollection({
        path: 'settings/price/services',
        docIdName: 'serviceId',
        orderBy: { fieldPath: 'pos', orderDir: 'asc' },
      }),
    }).then((result) => {
      setCategories(result.categories.filter((c) => c.isDeleted !== true))
      setServices(result.services.filter((s) => s.isDeleted !== true))
    })
  }, [])

  return (
    <RouteComponent
      title="Прайс"
      controlls={
        <>
          <Button
            title="Додати категорію"
            icon="plus"
            fill="accent"
            theme="solid"
            onClick={() => setPopUp({ type: 'cat' })}
            size={28}
          />
        </>
      }
    >
      {/* <div className="CitiesControll">
        {cities.map((c) => (
          <Button
            title={c.title}
            fill={c.id === activeCityId ? 'accent' : 'normal'}
            theme="solid"
            onClick={() => setActiveCityId(c.id)}
            size={28}
          />
        ))}
      </div> */}
      <div className="PriceContainer">
        {!isEmpty(categories) &&
          !isEmpty(services) &&
          categories.map((c) => (
            <CategoryPriceTile
              categoryData={c}
              services={services}
              setServices={setServices}
              setPopUp={setPopUp}
            />
          ))}
      </div>

      {popUp.type === 'cat' && (
        <CategoryManager
          // activeCityId={activeCityId}
          categoryId={popUp.instanceId}
          onClose={() => setPopUp({ type: null })}
        />
      )}
      {popUp.type === 'delCat' && (
        <DeleteCategoryManager
          categoryId={popUp.instanceId}
          close={() => togglePopUp({ type: null })}
        />
      )}
      {popUp.type === 'service' && (
        <ServiceManager
          categoryId={popUp.instanceId}
          serviceId={popUp.serviceId}
          onClose={() => setPopUp({ type: null })}
        />
      )}
    </RouteComponent>
  )
}

export default CategoryPrice
