import React from 'react'
import RouteComponent from '../RouteComponent/RouteComponent'
import PagesNav from './components/PagesNav'
import InfoPage from './components/InfoPage/InfoPage'
import { Route } from 'react-router-dom'
import './Pages.sass'
import Nauk from '../Nauk/'
import Nauka from '../Nauka/'
import Smi from '../Smi/'
import Contacts from '../Contacts/Contacts'

function Pages() {
  const pages = [
    {
      id: 'laser',
      path: '/pages/laser',
      title: 'Лазерне лікування',
      component: <InfoPage title="Лазерне лікування" docId="laser" />,
    },
    {
      id: 'about',
      path: '/pages/about',
      title: 'Про клініку',
      component: <InfoPage title="Про клініку" docId="about" />,
    },
    {
      id: 'optics',
      path: '/pages/optics',
      title: 'Про оптику',
      component: <InfoPage title="Про оптику" docId="optics" />,
    },
    {
      id: 'nauk',
      path: '/pages/nauk',
      title: 'Наукова діяльність',
      component: <Nauk />,
    },
    {
      id: 'smi',
      path: '/pages/smi',
      title: 'ЗМІ',
      component: <Smi />,
    },
    {
      id: 'contacts',
      path: '/pages/contacts',
      title: 'Контакти',
      component: <Contacts />,
    },
    {
      id: 'traning',
      path: '/pages/traning',
      title: 'Тренінги',
      component: <Nauka id="traning" title="Тренінги" />,
    },
    {
      id: 'konf',
      path: '/pages/konf',
      title: 'Наукові конференції',
      component: <Nauka id="konf" title="Наукові конференції" />,
    },
    {
      id: 'inshe',
      path: '/pages/inshe',
      title: 'Інші програми',
      component: <Nauka id="inshe" title="Інші програми" />,
    },
  ]

  return (
    <RouteComponent title={'Сторінки'}>
      <div className="PageContainer">
        <PagesNav pages={pages} />

        {pages.map((p) => (
          <Route
            key={`page-${p.id}`}
            path={p.path}
            render={() => p.component}
          />
        ))}
      </div>
    </RouteComponent>
  )
}

export default Pages
